import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingFinishLinePoint2IsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // F2 – can be followed by BS, BP, SS, L1, WP
    if (currentPoint.type.unitPointType == UnitPointType.FinishLinePoint2) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.Other:
            case UnitPointType.BoatStart:
            case UnitPointType.BuoyPoint:
            case UnitPointType.StageStart:
            case UnitPointType.StartLinePoint1:
            case UnitPointType.Waypoint:
                break
            default:
                return stagePointError(nextPoint, `F2 must be followed by BS/BP/SS/L1/WP - ${nextPoint.type.code} is invalid`)
        }
    }
}