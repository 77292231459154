import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingSplitPointIsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // SP – can be followed by WP, SP, BP, RS, CS, ZP, F1, SB, SF, QS, QF, TF (Winter Trial)
    if (currentPoint.type.unitPointType == UnitPointType.SplitPoint) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.Other:
            case UnitPointType.Waypoint:
            case UnitPointType.SplitPoint:
            case UnitPointType.BuoyPoint:
            case UnitPointType.RestrictionStart:
            case UnitPointType.ChicaneStart:
            case UnitPointType.ZeroSpeedPoint:
      //      case UnitPointType.FinishLinePoint1:
       //     case UnitPointType.SplitBoat:
            case UnitPointType.FlyingFinish:
            case UnitPointType.QuietZoneStart:
            case UnitPointType.QuietZoneFinish:
            case UnitPointType.TransitFinish:
                break
            default:
                return stagePointError(nextPoint, `SP must be followed by WP/SP/RS/CS/ZP/SF/QS/QF/TF - ${nextPoint.type.code} is invalid`)
        }
    }
}