import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingRestrictionFinishIsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // RF – can be followed by WP, SP, BP, RS, CS, ZP, F1, SB, SF
    if (currentPoint.type.unitPointType == UnitPointType.RestrictionFinish) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.Other:
            case UnitPointType.Waypoint:
            case UnitPointType.SplitPoint:
            case UnitPointType.BuoyPoint:
            case UnitPointType.RestrictionStart:
            case UnitPointType.ChicaneStart:
            case UnitPointType.ZeroSpeedPoint:
     //       case UnitPointType.FinishLinePoint1:
      //      case UnitPointType.SplitBoat:
            case UnitPointType.FlyingFinish:
                break
            default:
                return stagePointError(nextPoint, `RF must be followed by WP/SP/RS/CS/ZP/SF - ${nextPoint.type.code} is invalid`)
        }
    }
}