import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingFinishLinePoint1IsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // F1 – can be followed by F2
    if (currentPoint.type.unitPointType == UnitPointType.FinishLinePoint1) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.Other:
            case UnitPointType.FinishLinePoint2:
                break
            default:
                return stagePointError(nextPoint, `F1 must be followed by F2 - ${nextPoint.type.code} is invalid`)
        }
    }
}