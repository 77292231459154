import { Entry } from "../store/Entry"
import { PolyPoint, RoutePoint, StagePoint, UnitStagePoint } from "../store/Itinerary"
import { LiteMessage, MoMessage } from "../store/Unit"
import * as Extent from 'ol/extent'
import { isEqual } from 'lodash'
import { Map } from 'ol'
import { CoordinateSystem } from "../store/Map"

const badLocations = [0, 0.0, 1, 1.0, 1.1]

export const locationIsValid = (lat: number, lng: number) => {
    return lat != 0 && lat != 1 && lng != 0 && lng != 1
}

export const msgHasLocation = (msg: MoMessage | undefined | null) => {
    if (!msg) return false
    if (badLocations.includes(msg.latitude) || badLocations.includes(msg.longitude)) {
        return (!badLocations.includes(msg.lat ?? 0) && (!badLocations.includes(msg.lng ?? 0)))
    }
    return true
}

export const liteMsgHasLocation = (msg: LiteMessage | undefined | null) => {
    if (!msg) return false
    return (!(badLocations.includes(msg.latitude ?? 0) && badLocations.includes(msg.longitude ?? 0)))
}

export const stagePointHasLocation = (stagePoint: StagePoint | undefined | null) => {
    if (!stagePoint || !stagePoint.geoLocation) return false
    return !badLocations.includes(stagePoint.geoLocation.latitude) && !badLocations.includes(stagePoint.geoLocation.longitude)
}

export const unitStagePointHasLocation = (stagePoint: UnitStagePoint | undefined | null) => {
    if (!stagePoint) return false
    return !badLocations.includes(stagePoint.latitude ?? 0) && !badLocations.includes(stagePoint.longitude ?? 0)
}

export const getMsgLatitude = (msg: MoMessage | undefined | null) => {
    if (!msg) return 0
    if (msg.latitude == 0) {
        return msg.lat ? msg.lat : 0
    } else {
        return msg.latitude
    }
}

export const getMsgLongitude = (msg: MoMessage | undefined | null) => {
    if (!msg) return 0
    if (msg.longitude == 0) {
        return msg.lng ? msg.lng : 0
    } else {
        return msg.longitude
    }
}

export const getStagePointLatitude = (stagePoint: StagePoint | undefined | null) => {
    if (!stagePoint || !stagePoint.geoLocation) return 0
    return stagePoint.geoLocation.latitude ? stagePoint.geoLocation.latitude : 0
}

export const getStagePointLongitude = (stagePoint: StagePoint | undefined | null) => {
    if (!stagePoint || !stagePoint.geoLocation) return 0
    return stagePoint.geoLocation.longitude ? stagePoint.geoLocation.longitude : 0
}

export const getUnitStagePointLatitude = (stagePoint: UnitStagePoint | undefined | null) => {
    if (!stagePoint) return 0
    return stagePoint.latitude ? stagePoint.latitude : 0
}

export const getUnitStagePointLongitude = (stagePoint: UnitStagePoint | undefined | null) => {
    if (!stagePoint) return 0
    return stagePoint.longitude ? stagePoint.longitude : 0
}

export const polyPointHasLocation = (polypoint: PolyPoint | undefined | null) => {
    if (!polypoint) return false
    return !badLocations.includes(polypoint.lat) && !badLocations.includes(polypoint.long)
}

export const routePointHasLocation = (polypoint: RoutePoint | undefined | null) => {
    if (!polypoint) return false
    return !badLocations.includes(polypoint.lat) && !badLocations.includes(polypoint.long)
}

export const getEntryLatitude = (entry: Entry | undefined | null) => {
    if (!entry || !entry.lat) return 0
    return entry.lat
}

export const getEntryLongitude = (entry: Entry | undefined | null) => {
    if (!entry || !entry.lng) return 0
    return entry.lng
}

export function rhumbBearing(lat1: number, lng1: number, lat2: number, lng2: number) {
    // Convert to radians
    lat1 = lat1 * Math.PI / 180
    lat2 = lat2 * Math.PI / 180

    var deltaLambda = (lng2 - lng1) * Math.PI / 180
    if (Math.abs(deltaLambda) > Math.PI) {
        deltaLambda = (deltaLambda > 0) ?
            -(2 * Math.PI - deltaLambda) :
            (2 * Math.PI + deltaLambda)
    }

    var deltaPhi = Math.log(Math.tan(lat2 / 2 + Math.PI / 4) /
        Math.tan(lat1 / 2 + Math.PI / 4))
    var theta = Math.atan2(deltaLambda, deltaPhi)
    return ((theta * 180 / Math.PI) + 360) % 360
}

export const zoomToExtent = (newExtent: Extent.Extent, map: Map, resolution?: number) => {
    if (!isEqual(newExtent, [0, 0, 0, 0])) {//&& !isEqual(zoomToExtent.extent, view)) {
        map.getView().fit(newExtent, { minResolution: resolution ? resolution : 0.3, padding: [160, 90, 140, 90] })
        return newExtent
    }
}

export const getConvertedCoordinates = (lat: number, long: number, coordinateSystem: CoordinateSystem) => {
    const convertCoordinate = (value: number) => {
        switch (coordinateSystem) {
            case 'dd':
                return value.toFixed(6)
            case 'dms': {
                let degrees = Math.floor(value)
                let minutes = Math.floor((value - degrees) * 60)
                let seconds = Math.round(((value - degrees) * 60 - minutes) * 60)
                return `${degrees}° ${minutes}' ${seconds}"`
            }
            default:
                return value
        }
    }

    return `${convertCoordinate(lat)}, ${convertCoordinate(long)}`
}
