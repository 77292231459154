import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingBuoyPointIsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // BP – can be followed by L1, F1, SB, BP, ZP, BS, SS, SP, WP, SF
    if (currentPoint.type.unitPointType == UnitPointType.BuoyPoint) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.Other:
            case UnitPointType.FinishLinePoint1:
            case UnitPointType.SplitBoat:
            case UnitPointType.BuoyPoint:
            case UnitPointType.ZeroSpeedPoint:
            case UnitPointType.BoatStart:
            case UnitPointType.StageStart:
            case UnitPointType.SplitPoint:
            case UnitPointType.Waypoint:
            case UnitPointType.FlyingFinish:
                break
            default:
                return stagePointError(nextPoint, `BP must be followed by L1/F1/SB/BP/ZP/BS/SS/SP/WP/SF - ${nextPoint.type.code} is invalid`)
        }
    }
}