import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingZeroSpeedPointIsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // ZP – can be followed by WP, ZP, SP, SF, CS, RS
    if (currentPoint.type.unitPointType == UnitPointType.ZeroSpeedPoint) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.Other:
            case UnitPointType.Waypoint:
            case UnitPointType.ZeroSpeedPoint:
            case UnitPointType.SplitPoint:
            case UnitPointType.FlyingFinish:
            case UnitPointType.ChicaneStart:
            case UnitPointType.RestrictionStart:
                break
            default:
                return stagePointError(nextPoint, `ZP must be followed by WP/ZP/SP/SF/CS/RS - ${nextPoint.type.code} is invalid`)
        }
    }
}