import { Stack, Switch, Theme, Tooltip, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import { isNull, isUndefined } from 'lodash'

type Props = {
    value?: boolean
    label: string
    onOff?: boolean
    yesNo?: boolean
    allowAuto?: boolean
    onChange: (v?: boolean | null) => void
    sx?: SxProps<Theme>
    disabled?: boolean
    tooltip?: string
    required?: boolean
}

export const BooleanEditor: React.FunctionComponent<Props> = ({ required, tooltip, value, label, onOff, yesNo, allowAuto, onChange, sx, disabled }) => {
    const isAuto = isNull(value) || isUndefined(value)

    let preText = onOff
        ? value ? 'On' : 'Off'
        : yesNo && !isAuto
            ? value ? 'Yes' : 'No'
            : ''

    if (allowAuto && isAuto) {
        preText = 'Auto'
    }

    const style: SxProps = isAuto
        ? { '& .MuiSwitch-switchBase': { left: '10px' } }
        : {}

    const toggle = () => {
        if (allowAuto) {
            if (value === false)
                onChange(undefined)
            else if (isAuto)
                onChange(true)
            else
                onChange(false)
        } else {
            onChange(!value)
        }
    }

    return <Tooltip title={tooltip ?? ''} placement='right'>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} sx={sx}>
            <Typography>{label}</Typography>
            <Stack direction='row' alignItems='center'>
                <Typography>{preText}</Typography>
                <Switch
                    //size='small'
                    checked={isAuto ? false : value}
                    sx={style}
                    required={required && isAuto}
                    disabled={disabled}
                    onChange={(e) => toggle()}
                    onTouchEnd={(e) => toggle()}
                />
            </Stack>
        </Stack>
    </Tooltip>
}
