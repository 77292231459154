import InfoIcon from '@mui/icons-material/Info'
import NotificationsIcon from '@mui/icons-material/Notifications'
import WarningIcon from '@mui/icons-material/Warning'
import { Badge, CircularProgress, ClickAwayListener, Container, Grow, IconButton, Paper, Popper, Stack, Typography, useMediaQuery } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { blue, orange, red } from '@mui/material/colors'
import { styled, useTheme } from '@mui/material/styles'
import { Howl } from 'howler'

import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import { Dispatch } from 'redux'
import { requestNotifications } from '../../../api/Event'
import { getInitialNumberValue } from '../../../helper/StorageHelper'
import { useInterval } from '../../../helper/useInterval'
import { useStickyState } from '../../../helper/useStickyState'
import { ApplicationState } from '../../../store'
import { CompanyEditor, SafetyOfficer, hasAccess } from '../../../store/Account'
import { Notification as EventNotification, NotificationType, eventActionCreators } from '../../../store/Event'
import { globalActionCreators } from '../../../store/Global'
import { BeamTabletTime } from '../../../store/Times'
import HybridIcon from '../../@Global/images/hybrid.png'
import { BooleanEditor } from '../../Inputs/Editors/BooleanEditor'
import { NotificationRow } from './NotificationRow'

const PREFIX = 'NotificationsButton'

const classes = {
    paper: `${PREFIX}-paper`,
    list: `${PREFIX}-list`,
    loading: `${PREFIX}-loading`
}

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.paper}`]: {
        transformOrigin: 'top right',
        minWidth: 414,
    },

    [`& .${classes.list}`]: {
        width: theme.spacing(40),
        overflow: 'auto',
    },

    [`& .${classes.loading}`]: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(0.75, 0),
    }
}))

type Props = {
    eventId: number
    sound: Howl
}

declare module 'notistack' {
    interface VariantOverrides {
        beamTabletTime: true
    }
    interface OptionsObject {
        beamTabletTime?: BeamTabletTime
    }
}

const NotificationsButton: React.FunctionComponent<Props> = ({ eventId }) => {
    const notificationsUnread = useSelector((state: ApplicationState) => state.global.notificationsUnread)
    const roles = useSelector((state: ApplicationState) => state.identity.roles)
    const showNotifications = useSelector((state: ApplicationState) => state.event.showNotifications)

    const [open, setOpen] = useState<null | HTMLElement>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [notificationTypes, setNotificationTypes] = useStickyState<NotificationType[]>([NotificationType.Error, NotificationType.Warning, NotificationType.Notification], `notification-types-${eventId}`)
    const [notifications, setNotifications] = useState<EventNotification[]>([])
    const [filteredNotifications, setFilteredNotifications] = useState<EventNotification[]>([])


    const dispatch: Dispatch<any> = useDispatch()

    const theme = useTheme()
    const screenExtraSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const screenMedium = useMediaQuery(theme.breakpoints.down('lg'))

    const openRef = React.useRef(open)

    const notificationsUnreadRef = React.useRef(notificationsUnread)


    useInterval(() => {
        let localNotificationsUnreadValue = getInitialNumberValue(`notificationsUnread-${eventId}`) ?? 0
        if (localNotificationsUnreadValue != notificationsUnread) {
            dispatch(globalActionCreators.setNotificationsUnread(localNotificationsUnreadValue, eventId))
        }
    }, 3000)

    useEffect(() => {
        openRef.current = open
        notificationsUnreadRef.current = notificationsUnread
    })

    useEffect(() => {
        let f = [...notifications.filter(x => notificationTypes.includes(x.type) || x.type == NotificationType.IncomingMessage)]
        setFilteredNotifications(f)
    }, [notifications.length, notificationTypes])

    const getNotifications = () => {
        setLoading(true)
        requestNotifications(eventId)
            .then(result => {
                setNotifications(result)
            })
            .catch((err: string) => console.log(err))
            .finally(() => setLoading(false))
    }

    const onChangeNotificationTypes = (event: React.MouseEvent<HTMLElement>, newNotificationTypes: NotificationType[]) => {
        setNotificationTypes(newNotificationTypes)
    }

    const getItemSize = index => {
        return filteredNotifications[index].details.length > 70 ? 80 : 65
    }

    const row = (rowProps: ListChildComponentProps) => {
        const { index, style } = rowProps
        let notification = filteredNotifications[index]

        if (notification) {
            return <NotificationRow
                notification={notification}
                style={style}
            />
        } else {
            return null
        }
    }

    return (
        <Root>
            <IconButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    setOpen(event.currentTarget)
                    dispatch(globalActionCreators.setNotificationsUnread(0, eventId))
                    getNotifications()
                }}
                color="inherit"
                key='notifications'
                size={screenMedium ? 'medium' : 'large'}
            >
                <Badge badgeContent={notificationsUnread} color="primary">
                    <NotificationsIcon style={{ width: 28, height: 28 }} />
                </Badge>
            </IconButton>
            <Popper
                open={Boolean(open)}
                anchorEl={open}
                placement="bottom-end"
                disablePortal
                modifiers={[{
                    name: 'flip',
                    enabled: true
                }]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener
                        onClickAway={() => {
                            setOpen(null)
                            dispatch(globalActionCreators.setNotificationsUnread(0, eventId))
                        }}
                    >
                        <Grow in={Boolean(open)} {...TransitionProps}>
                            <Paper className={classes.paper} elevation={3}>
                                <div style={{ padding: '12px 14px 28px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Stack direction='row' alignItems='center' spacing={1}>
                                        <Typography style={{ float: 'left', fontSize: 24, marginTop: -2 }} variant={'h5'}>Notifications</Typography>

                                        {loading && <CircularProgress size={20} style={{ alignSelf: 'center' }} />}
                                        <BooleanEditor
                                            value={showNotifications}
                                            onOff
                                            label=''
                                            onChange={(x) => dispatch(eventActionCreators.setShowNotifications(x ?? true))}
                                        />
                                    </Stack>
                                    <ToggleButtonGroup style={{ height: 'fit-content' }} size={'small'} value={notificationTypes} onChange={onChangeNotificationTypes}>
                                        <ToggleButton value={NotificationType.Error}>
                                            <WarningIcon htmlColor={red[500]} />
                                        </ToggleButton>
                                        <ToggleButton value={NotificationType.Warning}>
                                            <WarningIcon htmlColor={orange[500]} />
                                        </ToggleButton>
                                        <ToggleButton value={NotificationType.Notification}>
                                            <InfoIcon htmlColor={blue[500]} />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                {filteredNotifications.length > 0
                                    ? <VariableSizeList height={400} width={screenExtraSmall ? '98%' : 500} itemSize={getItemSize} itemCount={filteredNotifications.length} className={classes.list}>
                                        {row}
                                    </VariableSizeList>
                                    : <Container>
                                        <Typography>No notifications for you</Typography>
                                    </Container>
                                }
                            </Paper>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
        </Root>
    )
}

export default React.memo(NotificationsButton)
