import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingTransitFinishIsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // TF – can be followed by TS, SS, WP
    if (currentPoint.type.unitPointType == UnitPointType.TransitFinish) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.Other:
            case UnitPointType.TransitStart:
            case UnitPointType.StageStart:
            case UnitPointType.Waypoint:
                break
            default:
                return stagePointError(nextPoint, `TF must be followed by TS/SS/WP - ${nextPoint.type.code} is invalid`)
        }
    }
}