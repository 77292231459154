import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'

import * as Api from '../api/Equipment'
import { EquipmentStatus } from './Unit'

export enum EquipmentScanType {
    Barcode = 0,
    Typed,
    Mobile
}

export enum LocationType {
    Event,
    Location
}

export enum EquipmentScanStatus {
    Pending,
    Completed,
    Warning,
    Error,
    WhoKnows
}

export interface EquipmentLocationBasic {
    equipmentLocationId: number
    name: string
}

export interface EquipmentLocation {
    equipmentLocationId: number
    name: string
    isArchived: boolean
}

export interface Equipment {
    equipmentId: number
    manufactureDate: string
    identifier: number | null
    unitSerialNumber: number | null
    equipmentType: EquipmentType
    lastScan: string
    equipmentTypeId: number
    location: string
}

export interface EquipmentModel extends Equipment {
    imeinumber: number | null
    iridiumStatus: boolean | null
    gsmImei: string
    sim: string
    serialNumberLength: number | null
    locationId?: number
    comment: string
}

export interface EquipmentFaultModel {
    equipmentFaultId: number
    equipmentId: number
    equipmentFaultType: string
    note: string
    userName: string
    time: string
    identifier: string
    equipmentType: EquipmentType
    lastTested: string
}

export interface EquipmentFaultCreateModel {
    equipmentId: number
    equipmentFaultType: string
    note: string
}

export const blankEquipmentFaultCreateModel: EquipmentFaultCreateModel = {
    equipmentId: -1,
    equipmentFaultType: '',
    note: ''
}

export interface EquipmentNoteModel {
    equipmentNoteId: number
    equipmentId: number
    note: string
    user: string
    enteredTime: string
    identifier: string
    equipmentType: EquipmentType
    status: EquipmentStatus
}

export interface EquipmentNoteCreateModel {
    equipmentId: number
    note: string
    status: EquipmentStatus
}

export const blankEquipmentNoteCreateModel: EquipmentNoteCreateModel = {
    equipmentId: -1,
    note: '',
    status: EquipmentStatus.NeedsChecking
}

export const blankEquipment: EquipmentModel = {
    equipmentId: -1,
    identifier: null,
    unitSerialNumber: null,
    equipmentTypeId: -1,
    manufactureDate: '',
    location: '',
    equipmentType: {
        equipmentTypeId: -1,
        name: '',
        code: '',
        length: 1,
        totalNumber: 0
    },
    lastScan: '',
    imeinumber: null,
    iridiumStatus: null,
    gsmImei: '',
    sim: '',
    serialNumberLength: null,
    comment: ''
}

export interface EquipmentType {
    equipmentTypeId: number
    name: string
    code: string
    length: number
    totalNumber: number
}

export const blankEquipmentType: EquipmentType = {
    equipmentTypeId: -1,
    code: '',
    name: '',
    length: 0,
    totalNumber: 0
}

export interface EquipmentLogBasic {
    equipmentLogId: number
    location: string
    equipmentTypeId: number
    identifier: number
    logDate: Date
    logUser: string
    box: number
    equipmentScanType: EquipmentScanType
    isArchived: boolean
    entryIdentifier: string
    comment: string
}

export interface EquipmentLogDetailed {
    equipmentLogId: number
    logUser: string
    logDate: Date
    box: number | null
    isArchived: boolean
    equipment: Equipment
    equipmentScanType: EquipmentScanType
    equipmentLocation: EquipmentLocation | null
    event: Event | null
}


export interface PendingScans {
    status: EquipmentScanStatus
    scanType: EquipmentScanType
    equipmentType: number
    identifier: string
    location: string
    note: string
}

export interface EquipmentBarcodeInfo {
    identifier: string
    code: string
    manufactureMonth: number
    manufactureYear: number
    type: EquipmentType
}

export interface EquipmentScanModel {
    equipmentLogId: number
    sequence: number
    identifier: string
    entryIdentifier: string
    equipmentTypeId: number
    fromType: LocationType
    toType: LocationType
    locationFromId: number | null
    locationToId: number | null
    eventId: number | null
    logUser: string
    logDate: string
    equipmentScanType: EquipmentScanType
    overrideWarnings: boolean
    manufactureMonth: number
    manufactureYear: number
    scanStatus: EquipmentScanStatus
    faultCodeName: string
    faultNote: string
    lastLocation: string | null
    errors: string[] | null
    warnings: string[] | null
}

export const blankEquipmentScanModel: EquipmentScanModel = {
    equipmentLogId: -1,
    sequence: 0,
    identifier: '',
    entryIdentifier: '',
    equipmentTypeId: -1,
    fromType: LocationType.Location,
    toType: LocationType.Event,
    locationFromId: null,
    locationToId: null,
    eventId: null,
    logUser: '',
    logDate: '',
    equipmentScanType: EquipmentScanType.Barcode,
    overrideWarnings: false,
    manufactureMonth: -1,
    manufactureYear: -1,
    scanStatus: EquipmentScanStatus.WhoKnows,
    faultCodeName: '',
    faultNote: '',
    lastLocation: '',
    errors: null,
    warnings: null
}

export interface ResponseModel {
    equipmentLogId: number
    identifier: string
    entryIdentifier: string
    equipmentTypeId: number
    lastLocation: string | null
    faultCode: string | null
    faultNote: string | null
    errors: string[] | null
    warnings: string[] | null
}

export interface EquipmentLocationModel extends EquipmentLocation {
    units: number
    tablets: number
    xBees: number
    rfD868: number
    rfD900: number
    beams: number
    startClocks: number
    others: number
}

export const blankEquipmentLocation: EquipmentLocationModel = {
    equipmentLocationId: -1,
    name: '',
    units: 0,
    tablets: 0,
    xBees: 0,
    rfD868: 0,
    rfD900: 0,
    beams: 0,
    startClocks: 0,
    others: 0,
    isArchived: false
}

export interface UnreturnedEquipment {
    evhicleId: number | null
    identifier: string
    equipmentTypeName: string
    driver: string
    navigator: string
    driverNumber: string
    navigatorNumber: string
}

export interface EquipmentRemaining {
    count: number
    identifiers: string[]
    typeName: string
    typeId: number
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EquipmentState {
    equipmentLogsPending: boolean
    equipments: Equipment[]
    equipmentLogs: EquipmentLogBasic[]
    equipmentTypes: EquipmentType[]
    equipmentLocations: EquipmentLocationBasic[]
    count: number
    pendingScans: EquipmentScanModel[]
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestEquipmentLogs { type: 'REQUEST_EQUIPMENT_LOGS' }
interface ReceiveEquipments { type: 'RECEIVE_EQUIPMENTS', equipments: Equipment[], append: boolean }
interface ReceiveEquipmentLogs { type: 'RECEIVE_EQUIPMENT_LOGS', equipmentLogs: EquipmentLogBasic[], count: number, append: boolean }
interface ReceiveEquipmentTypes { type: 'RECEIVE_EQUIPMENT_TYPES', equipmentTypes: EquipmentType[] }
interface ReceiveEquipmentLocations { type: 'RECEIVE_EQUIPMENT_LOCATIONS', equipmentLocations: EquipmentLocationBasic[] }
interface ReceiveFailed { type: 'RECEIVE_FAILED' }
interface ClearEquipments { type: 'CLEAR_EQUIPMENTS' }
interface ClearEquipmentLogs { type: 'CLEAR_EQUIPMENT_LOGS' }
interface AddOrUpdateEquipmentScans { type: 'ADD_OR_UPDATE_EQUIPMENT_SCANS', pendingScan: EquipmentScanModel }
interface ClearEquipmentScans { type: 'CLEAR_EQUIPMENT_SCANS' }
interface RemoveEquipmentScan { type: 'REMOVE_EQUIPMENT_SCAN', pendingScan: EquipmentScanModel }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestEquipmentLogs | RemoveEquipmentScan | ReceiveEquipments | ReceiveEquipmentLogs | ReceiveEquipmentTypes | ReceiveEquipmentLocations | ReceiveFailed | ClearEquipments | ClearEquipmentLogs | AddOrUpdateEquipmentScans | ClearEquipmentScans


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const equipmentActionCreators = {
    requestEquipmentLogs: (skip: number, take: number, searchText: string, order: string, append: boolean, selectedEvent: number, selectedLocation: number, selectedType: number, onlyLatest: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_EQUIPMENT_LOGS' })
        Api.requestEquipmentLogs(skip, take, searchText, order, selectedEvent, selectedLocation, selectedType, onlyLatest)
            .then(result => dispatch({
                type: 'RECEIVE_EQUIPMENT_LOGS',
                equipmentLogs: result.rows as EquipmentLogBasic[],
                count: result.count,
                append: append
            }))
            .catch((err: number) => dispatch({
                type: 'RECEIVE_FAILED'
            }))
    },
    requestEquipmentTypes: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        Api.requestEquipmentTypes()
            .then(result => dispatch({
                type: 'RECEIVE_EQUIPMENT_TYPES',
                equipmentTypes: result
            }))
            .catch((err: number) => dispatch({
                type: 'RECEIVE_FAILED'
            }))
    },
    receiveEquipmentTypes: (equipmentTypes: EquipmentType[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RECEIVE_EQUIPMENT_TYPES', equipmentTypes: equipmentTypes })
    },
    receiveEquipmentLocations: (includeArchived?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        Api.requestEquipmentLocations(includeArchived)
            .then(result => dispatch({
                type: 'RECEIVE_EQUIPMENT_LOCATIONS',
                equipmentLocations: result
            }))
            .catch((err: number) => console.log(err))
    },
    removeEquipmentScan: (pendingScan: EquipmentScanModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REMOVE_EQUIPMENT_SCAN', pendingScan: pendingScan })
    },
    clearEquipmentLogs: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_EQUIPMENT_LOGS' })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloaded: EquipmentState = {
    equipmentLogsPending: false,
    equipments: [],
    equipmentLogs: [],
    equipmentTypes: [],
    equipmentLocations: [],
    count: 0,
    pendingScans: []
}

export const reducer: Reducer<EquipmentState> = (state: EquipmentState | undefined, incomingAction: Action): EquipmentState => {
    if (state === undefined) return unloaded
    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'REQUEST_EQUIPMENT_LOGS':
            return {
                ...state,
                equipmentLogsPending: true
            }
        case 'RECEIVE_EQUIPMENT_LOGS':
            let equipmentLogs = action.equipmentLogs
            if (action.append) {
                equipmentLogs = state.equipmentLogs.concat(action.equipmentLogs)
            }
            return {
                ...state,
                equipmentLogsPending: false,
                equipmentLogs: equipmentLogs,
                count: action.count
            }
        case 'RECEIVE_EQUIPMENT_TYPES':
            return {
                ...state,
                equipmentTypes: action.equipmentTypes
            }
        case 'RECEIVE_EQUIPMENT_LOCATIONS':
            return {
                ...state,
                equipmentLocations: action.equipmentLocations
            }
        case 'RECEIVE_FAILED':
            return {
                ...state,
                equipmentLogsPending: false
            }
        case 'CLEAR_EQUIPMENT_LOGS':
            return {
                ...state,
                equipmentLogs: []
            }
        case 'ADD_OR_UPDATE_EQUIPMENT_SCANS': {
            let newPendingScans = [...state.pendingScans]
            let existingPendingScans = state.pendingScans.find(e => e.identifier == action.pendingScan.identifier)
            if (existingPendingScans) {
                newPendingScans = [...state.pendingScans.filter(e => e.identifier != existingPendingScans?.identifier), action.pendingScan]
            } else {
                newPendingScans = [...state.pendingScans, action.pendingScan]
            }
            return {
                ...state,
                pendingScans: newPendingScans
            }
        }
        case 'REMOVE_EQUIPMENT_SCAN': {
            let newPendingScans = [...state.pendingScans
                .filter(e => !(e.identifier == action.pendingScan.identifier
                    && e.eventId == action.pendingScan.eventId
                    && e.locationFromId == action.pendingScan.locationFromId
                    && e.locationToId == action.pendingScan.locationToId))]

            return {
                ...state,
                pendingScans: newPendingScans
            }
        }
        case 'CLEAR_EQUIPMENT_SCANS':
            return {
                ...state,
                pendingScans: []
            }
        default:
            return state
    }
}



