import { Autocomplete, Box, Stack, TextField, Typography, Chip } from '@mui/material'
import * as React from 'react'
import '../../../helper/stringExtensions'
import { countryList } from '../../@Global/CountryList'
import { CountryFlagIcon } from '../../../assets/Icons/CountryFlagIcon'

type Props = {
    selectedCountries: string[]
    onChange: (countries: string[]) => void
    limit?: boolean
}

const uniqueCountryList = countryList.filter((v, i, a) => a.findIndex(t => t.code === v.code) === i)

export const CountryMultiSelect: React.FunctionComponent<Props> = ({ selectedCountries, limit, onChange }) => {

    const _selectedCountries = uniqueCountryList
        .filter(x => selectedCountries.includes(x.code))

    return (
        <Autocomplete
            sx={{ minWidth: '260px' }}
            size={'small'}
            multiple
            value={_selectedCountries}
            limitTags={limit ? 4 : -1}
            onChange={(e, v) => onChange(v.map(x => x.code))}
            options={uniqueCountryList}
            getOptionLabel={(option) => option.name}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip {...getTagProps({ index })} size='small' label={option.code3} />
                ))
            }

            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    <Stack
                        spacing={1}
                        alignItems='center'
                        direction='row'
                    >
                        <CountryFlagIcon size={32} code={option.code} />
                        <Typography>{option.name}</Typography>
                    </Stack>
                </Box>
            )}
            renderInput={(params) => (
                <TextField {...params} label='Countries' />
            )}
        />
    )
}
