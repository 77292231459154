import { stagePointError } from '.'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const stageNumberIsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // A stage number can’t be less than the line before it
    if (currentPoint.stageNumber < 121 && nextPoint.stageNumber < currentPoint.stageNumber
        && nextPoint.stageNumber != currentPoint.stageNumber - 60
        && nextPoint.stageNumber != currentPoint.stageNumber + 60 && nextPoint.stageNumber != currentPoint.stageNumber - 59) {
        return stagePointError(nextPoint, `Stage number can't be less than the line before it - ${nextPoint.stageNumber} is less than ${currentPoint.stageNumber}`)
    }

    // A stage number can’t be more than 123
    if (currentPoint.stageNumber > 123)
        return stagePointError(currentPoint, `Stage number can't be more than 123 - ${currentPoint.stageNumber} is more than 123`)
}
